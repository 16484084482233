import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import _ from "lodash";
import { useEffect } from "react";
import {
  Typography,
} from "@mui/material";
import { AuthBody } from "src/types/auth";
import { ToastInfo } from "src/types/toast";
import React from "react";
import { Toast } from "src/components/Common/Toast";
import { useAppDispatch } from "src/redux/store";
import { signInReducer } from "src/redux/features/authSlice";
import { LayoutAuth } from "src/components/Layouts/Auth";
import { InputField } from "src/components/Common/InputField";
import "./index.css";
import { AuthButton } from "src/components/Common/Button/AuthButton";

interface Props {}
/**
 * Form Validation Schema
 */

interface LoginFormProps {
  loading: boolean;
  handleLogin: (data: any) => void;
  handleGoForgetPassword: () => void;
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(6, "Password is too short - should be 6 chars minimum."),
});

const defaultValues = {
  email: "",
  password: "",
};

const SignInForm = (props: LoginFormProps) => {
  const { handleLogin, loading, handleGoForgetPassword } = props;

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setValue(
      "email",
      (typeof window !== "undefined" &&
        localStorage.getItem("registerEmail")) ||
        "",
      {
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  }, [setValue]);

  const onSubmit = (data: AuthBody) => {
    handleLogin(data);
  };

  return (
    <form
      name="loginForm"
      noValidate
      className="flex flex-col justify-center w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputField
            rest={{ ...field }}
            type="email"
            placeholder="Enter your email"
            error={!!errors.email}
            className={`emailField ${
              !!errors?.password && "border-b-0"
            } rounded-t-[8px]`}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <InputField
            rest={{ ...field }}
            className={`${
              !!errors?.password ? "border-t-2" : "border-t-0"
            } passwordField rounded-b-[8px]`}
            type="password"
            placeholder="Enter your password"
            error={!!errors.password}
            helperText={errors?.password?.message || errors?.email?.message}
          />
        )}
      />

      <AuthButton
        label="Sign in"
        disabled={_.isEmpty(dirtyFields) || !isValid}
        loading={loading}
        className="mt-3"
      />

      <Typography
        className="text-center cursor-pointer text-[13px] mt-6 font-sans font-medium"
        onClick={handleGoForgetPassword}
      >
        Forgot password?
      </Typography>
    </form>
  );
};

export const LoginPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [toastInfo, setToastInfo] = React.useState<ToastInfo>({
    text: "",
    open: false,
    severity: "warning",
    timeHideDuration: 6000,
  });

  const groupInfo =
    localStorage.getItem("groupInfo") &&
    JSON.parse(localStorage.getItem("groupInfo") || "");

  const handleGoForgetPassword = () => {
    navigate(`/${groupInfo?.group_route}/forgot-password`);
  };

  const handleLogin = async (data: any) => {
    setLoading(true);

    try {
      dispatch(signInReducer(data)).then((res: any) => {
        setLoading(false);

        if (res?.payload) {
          setToastInfo({
            text: "Login successfully !!!",
            open: true,
            severity: "success",
          });

          if (typeof window !== "undefined") {
            localStorage.setItem("access_token", res?.payload?.token);
            localStorage.removeItem("session_expired");
          }

          setTimeout(() => {
            navigate("/courses");
          }, 1500);
        } else {
          setToastInfo({
            text: res?.error?.message,
            open: true,
            severity: "error",
            timeHideDuration: 6000,
          });
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      !!localStorage.getItem("session_expired")
    ) {
      setToastInfo({
        text: "Your session has expired. Please log in again.!!!",
        open: true,
        severity: "info",
        timeHideDuration: null,
      });
    }
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setToastInfo({ ...toastInfo, open: false });
  };

  return (
    <LayoutAuth label="Login" nextPage="Sign up">
      <div className="w-full">
        <SignInForm
          handleLogin={handleLogin}
          loading={loading}
          handleGoForgetPassword={handleGoForgetPassword}
        />
        <Toast
          text={toastInfo.text}
          open={toastInfo.open}
          handleClose={handleClose}
          severity={toastInfo.severity}
          timeHideDuration={toastInfo.timeHideDuration}
        />
      </div>
    </LayoutAuth>
  );
};
