import React from "react";
import { DotsBackground } from "../Common/Icons";

type Props = {
  title?: string;
  height?: number;
  customStyle?: string
  styleBuddle?: string
};

export const TitleBubble = (props: Props) => {
  const { title, customStyle, styleBuddle , height } = props;

  return (
    <div className={`${customStyle} w-full bg-[#BAC9CF] ${height ? `h-[${height}px]` : 'h-[80px]'} border-b-[1px] divide-solid border-black relative z-10`}>
      <div className={`${styleBuddle} absolute overflow-hidden bottom-[-55px] w-[296px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-[1px] divide-solid border-black rounded-full flex items-center justify-center min-w-[200px] h-[56px] bg-white`}>
        <p className="text-[14px] font-light leading-[150%] uppercase">— {title} —</p>
        <DotsBackground className="absolute opacity-60 gap-2 flex items-center justify-center flex-wrap" />
      </div>
    </div>
  );
};
