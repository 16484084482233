import { Button, CircularProgress } from "@mui/material";
import { TitleBubble } from "../TitleBubble";
import { ChatInput } from "./ChatInput";
import { AudioPlayer } from "../AudioPlayer";
import "../Chat/index.css";

interface ChatProps {
  handleNextAction?: (data?: any) => void;
  chatDisplayRef?: any;
  messages?: any;
  isLoading?: boolean;
  setUserInput?: any;
  hideChat?: boolean;
  userInput?: any;
  title?: string;
  activeStep?: number;
  handleSendMessage?: () => void;
  setCompletedModal?: (open: boolean) => void;
  setActiveStep?: (step: number) => void;
}

export const Chat = (props: ChatProps) => {
  const {
    handleNextAction,
    chatDisplayRef,
    messages,
    isLoading,
    hideChat = false,
    setUserInput,
    handleSendMessage,
    setCompletedModal,
    userInput,
    activeStep,
    setActiveStep,
    title,
  } = props;

  return (
    <div
      className="flex w-full flex-col bg-white h-full justify-between border-1 border-solid border-black"
      id={"drawer-container"}
    >
      <TitleBubble
        height={80}
        title={title}
        styleBuddle="absolute top-1/2 -translate-y-1/2 md:top-0 left-1/2 transform -translate-x-1/2"
      />

      <div className="bg-chat h-full w-full flex flex-col scroller-chat">
        <div
          ref={chatDisplayRef}
          className="flex flex-col grow  gap-10 py-[36px] z-10 flex-1"
        >
          {messages
            ?.filter((message: any) => message?.type !== "audio")
            .map((message: any, index: number) => {
              return (
                <div key={index} className="flex flex-col items-start relative">
                  {message.user === "You" ? (
                    <p className="absolute text-[12px] leading-[150%] font-light bg-white rounded-full border-1 border-solid border-black h-[42px] w-[140px] flex items-center justify-center top-[-20px] right-[20px]">
                      {message.user}
                    </p>
                  ) : (
                    message.type !== "audio" &&
                    message.type !== "choice" && (
                      <p className="absolute text-[12px] leading-[150%] font-light bg-white rounded-full border-1 border-solid border-black h-[42px] w-[140px] flex items-center justify-center top-[-20px] left-[20px]">
                        {message.user}
                      </p>
                    )
                  )}
                  {message.type === "visual" ? (
                    <div
                      className={`${
                        message.type
                      } ${message.user.toLowerCase()}`}
                    >
                      <img src={message.payload.image} alt="" />
                    </div>
                  ) : message.type === "choice" ? (
                    <div
                      className={`${
                        message.type
                      } ${message.user.toLowerCase()} flex gap-2 items-center w-full`}
                    >
                      {Array.isArray(message.choices) &&
                        message.choices.map((choice: any, index: number) => {
                          return (
                            <button
                              key={index}
                              className={`w-full border-y-1 ${
                                index === 0
                                  ? "border-r-1 bg-white"
                                  : "border-l-1 bg-[#BACACF]"
                              } border-solid border-black uppercase rounded-none text-black font-[14px] font-medium leading-[150%] px-6 py-3`}
                              onClick={() => {
                                handleNextAction &&
                                  handleNextAction(choice.request);
                                if (choice.name === "Next Lesson")
                                  setActiveStep &&
                                    activeStep &&
                                    setActiveStep(activeStep + 1);

                                if (choice.name === "Finish Course")
                                setCompletedModal && setCompletedModal(true)
                              }}
                            >
                              {choice.name}
                            </button>
                          );
                        })}
                    </div>
                  ) : message.type === "cardV2" ? (
                    <div className={`cardV2 ${message.user.toLowerCase()}`}>
                      {message.imageUrl && (
                        <img src={message.imageUrl} alt={message.title || ""} />
                      )}
                      <div className={"card-bottom-container"}>
                        {message.title && <h4>{message.title}</h4>}
                        {message.description && (
                          <p>{message.description.text}</p>
                        )}
                        {message.buttons &&
                          message.buttons.map((button: any, i: number) => {
                            console.log(button.request); // Add this line
                            return (
                              <Button
                                key={i}
                                className="cardV2-button button-spacing"
                                onClick={() =>
                                  handleNextAction &&
                                  handleNextAction(button.request)
                                }
                                style={{
                                  textTransform: "none",
                                  marginBottom: "10px",
                                  paddingLeft: "5px",
                                  paddingRight: "5px",
                                  width: "225px",
                                }}
                              >
                                {button.name}
                              </Button>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        message.user.toLowerCase() +
                        (message.type === "text"
                          ? " w-full bg-white border-y-1 border-solid border-black px-6 py-12"
                          : "")
                      }
                    >
                      <p>{message.text}</p>
                    </div>
                  )}
                </div>
              );
            })}
        </div>

        {isLoading && (
          <div className="flex items-end justify-center mb-2">
            <CircularProgress />
          </div>
        )}
      </div>

      {!hideChat && (
        <ChatInput
          type="text"
          placeholder="Ask Me Anything..."
          value={userInput}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isLoading) {
              handleSendMessage && handleSendMessage();
            }
          }}
          onChange={(e) => setUserInput(e.target.value)}
        />
      )}

      <div className="w-full px-4 py-2 bg-black text-white text-center text-xs leading-[150%] font-light">
        © Copyright 2023 Psynapse Psychometrics Pty Ltd All Rights Reserved
      </div>
    </div>
  );
};
