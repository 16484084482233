import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { LayoutDashboard } from "src/components/Layouts/Dashboard";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { textToVoice } from "src/redux/api/voiceApi";
import { CircularProgress } from "@mui/material";
import { getAssistantMessages } from "src/helper";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getCourseById } from "src/redux/features/coursesEnrollmentsSlice";
import {
  getDataFromVoiceFlowViaVersionId,
  updateUserRatingCourse,
} from "src/redux/api/coursesEnrollmentsApi";
import { WarningAudio } from "src/components/Popup/WarningAudio";
import { Chat } from "src/components/Chat";
import { VerticalLinearStepper } from "src/components/Stepper";
import { CourseLeftLayout } from "src/components/Course/CourseLeftLayout";
import { CourseProgress } from "src/components/Course/CourseProgress";
import { StepperSkeleton } from "src/components/Skeletons/Stepper";
import { ExitCourseMessage } from "src/components/Popup/ExitCourse";
import { CourseCompleteMessage } from "src/components/Popup/CourseComplete";
import { ToastInfo } from "src/types/toast";
import { Toast } from "src/components/Common/Toast";

type Props = {};

export const ChatPage = (props: Props) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const chatDisplayRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { course } = useAppSelector(
    (state) => state.courseJoinedState.courseJoinedDetail
  );

  const [steps, setSteps] = React.useState([]);
  const [waringModal, setWarningModal] = useState(true);
  const [exitModal, setExitModal] = useState(false);
  const [completedModal, setCompletedModal] = useState(false);
  const [messages, setMessages] = useState([]) as any;
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = React.useState(-1) as any;
  const [loadingCourse, setLoadingCourse] = React.useState<boolean>(true);
  const [ratingLoading, setRatingLoading] = useState(false);
  const [rating, setRating] = React.useState<number | null>(0);

  const [toastInfo, setToastInfo] = React.useState<ToastInfo>({
    text: "",
    open: false,
    severity: "warning",
    timeHideDuration: 6000,
  });

  const handleSendMessage = async () => {
    if (userInput.trim() !== "") {
      // Add the user's message to the chat and clear the input field
      const newMessage = { user: "You", text: userInput };

      const newMessages = [...messages, newMessage];
      setMessages(newMessages);
      setUserInput("");

      // Process the next action
      handleNextAction(null, newMessages);
    }
  };

  const handleSelectStep = (index: number, lesson: string) => {
    setIsLoading(true);

    getDataFromVoiceFlowViaVersionId({
      action: {
        type: "intent",
        payload: { intent: { name: lesson } },
      },
      versionId: params?.id,
      apiKey: course?.fields?.api_key,
    })
      .then(async (res: any) => {
        const { data } = res;
        setIsLoading(false);
        setActiveStep(index);

        if (Array.isArray(data) && data.length > 0) {
          const assistantMessages = getAssistantMessages(data);

          if (assistantMessages.length > 0) {
            if (
              assistantMessages.some((item: any) =>
                item.type.includes("choice")
              )
            ) {
              let temp = [];

              temp = messages.filter((item: any) => item.type !== "choice");
              setMessages([...temp, ...assistantMessages]);
            } else
              setMessages((prevMessages: any) => [
                ...prevMessages,
                ...assistantMessages,
              ]);

            processAssistantMessages(
              assistantMessages,
              course?.fields?.voice_enabled
            );
            console.log("Assistant messages:", assistantMessages);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log({ error });
      });
  };

  const handleNextAction = async (payload?: any, newMess?: any) => {
    setIsLoading(true);

    getDataFromVoiceFlowViaVersionId({
      action: payload || { type: "text", payload: userInput },
      versionId: params?.id,
      apiKey: course?.fields?.api_key,
    })
      .then(async (res: any) => {
        const { data } = res;
        setIsLoading(false);

        if (Array.isArray(data) && data.length > 0) {
          const assistantMessages = getAssistantMessages(data);

          if (assistantMessages.length > 0) {
            if (
              assistantMessages.some((item: any) =>
                item.type.includes("choice")
              )
            ) {
              let temp = [];

              temp = messages.filter((item: any) => item.type !== "choice");
              if (newMess && newMess?.length > 0)
                temp = newMess.filter((item: any) => item.type !== "choice");
              else
                temp = messages.filter((item: any) => item.type !== "choice");

              setMessages([...temp, ...assistantMessages]);
            } else
              setMessages((prevMessages: any) => [
                ...prevMessages,
                ...assistantMessages,
              ]);

            processAssistantMessages(
              assistantMessages,
              course?.fields?.voice_enabled
            );
            console.log("Assistant messages:", assistantMessages);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log({ error });
      });
  };

  const synthesizeToSpeech = async (message: string) => {
    // Returns a promise that resolves when the audio has ended
    return new Promise(async (resolve, reject) => {
      try {
        const response = await textToVoice(message);
        const audioDataURI = response.data;
        const audio = new Audio(audioDataURI);

        audio.onended = resolve;
        audio.onerror = reject;
        const playedPromise = audio?.play();

        if (playedPromise) {
          playedPromise
            .catch((e) => {
              console.log(e);
              if (
                e.name === "NotAllowedError" ||
                e.name === "NotSupportedError"
              ) {
                console.log(e.name);
              }
            })
            .then((res) => {
              console.log({ res });
            });
        }
      } catch (error) {
        console.error("Error occurred during text-to-speech:", error);
        reject(error);
      }
    }).catch((error) => console.log({ error }));
  };

  const playAudio = (src: string | undefined): Promise<void> => {
    // Returns a promise that resolves when the audio has ended
    return new Promise<void>((resolve, reject) => {
      if (audioRef.current) {
        audioRef.current.src = src || "";
        audioRef.current
          .play()
          .then(() => {
            console.log("Audio started playing.");
            src &&
              document.querySelector(".audioWrapper")?.setAttribute("src", src);
            if (audioRef.current) {
              audioRef.current.onended = () => {
                console.log("Audio ended", src);
                resolve();
              };
            }
          })
          .catch((error) => {
            console.error("Audio play promise rejected:", error);
            reject(error);
          });
      } else {
        reject(new Error("audioRef is not available."));
      }
    });
  };

  // Function to process the assistant's messages
  const processAssistantMessages = async (
    assistantMessages: any,
    voiceEnabled: boolean
  ) => {
    // Loop through each message and handle it according to its type
    for (const message of assistantMessages) {
      if (message.type === "audio") {
        console.log("About to play audio", message.src);
        handlePlayAudio(message.src);
      } else if (message.type === "text" && voiceEnabled) {
        await synthesizeToSpeech(message.text);
      }
    }
  };

  const handlePlayAudio = (src: string) => {
    let time = 0;
    const myInterval = setInterval(async () => {
      time++;
      if (audioRef.current) {
        try {
          playAudio(src);
        } catch (error) {
          console.log({ error });
        }
        clearInterval(myInterval);
      }
      if (time === 30) {
        clearInterval(myInterval);
      }
    }, 100);
  };

  const handleFeedback = async () => {
    setRatingLoading(true)
    await updateUserRatingCourse(course.id, { rating })
      .then((res: any) => {
        setToastInfo({
          text: res.message,
          open: true,
          severity: "success",
          timeHideDuration: 3000,
        });
      })
      .catch((error: any) => {
        setToastInfo({
          text: error.message,
          open: true,
          severity: "error",
          timeHideDuration: 3000,
        });
      });
    setRatingLoading(false)
  };

  const handleCloseToast = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setToastInfo({ ...toastInfo, open: false });
  };

  useEffect(() => {
    setIsLoading(true);

    !waringModal &&
      !loadingCourse &&
      getDataFromVoiceFlowViaVersionId({
        action: { type: "launch" },
        versionId: params?.id,
        apiKey: course?.fields?.api_key,
      })
        .then((res: any) => {
          const { data } = res;
          setIsLoading(false);

          if (Array.isArray(data) && data.length > 0) {
            const assistantMessages = getAssistantMessages(data);
            if (assistantMessages.length > 0) {
              setMessages((prevMessages: any) => [
                ...prevMessages,
                ...assistantMessages,
              ]);

              processAssistantMessages(
                assistantMessages,
                course?.fields?.voice_enabled
              );
              console.log("Assistant messages:", assistantMessages);
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log({ error });
        });
  }, [loadingCourse, waringModal]);

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    dispatch(getCourseById(searchParams.get("course-id"))).then((res: any) => {
      setActiveStep(Number(res?.payload?.course?.fields?.current_step));
      setLoadingCourse(false);
    });
  }, []);

  React.useEffect(() => {
    course &&
      setSteps(
        course?.fields.toc &&
          JSON.parse(course?.fields.toc?.replace(/^"|"$/g, ""))
            ?.academy_demo_course_steps?.value
      );
  }, [course]);

  return (
    <LayoutDashboard hideSettings handleExit={() => setExitModal(true)}>
      <div className="flex justify-center w-full h-full gap-3 relative">
        <CourseLeftLayout
          titleBubble="lesson toc"
          course={course}
          loading={loadingCourse}
        >
          <div className="flex flex-col w-full h-full gap-4">
            <CourseProgress
              progress={course?.fields?.progress}
              loading={loadingCourse}
            />
            {loadingCourse ? (
              [1, 2, 3].map((item) => <StepperSkeleton key={item} />)
            ) : steps?.length > 0 ? (
              <VerticalLinearStepper
                steps={steps}
                messages={messages}
                activeStep={activeStep}
                handleSelectStep={handleSelectStep}
                audioRef={audioRef}
                handleNextAction={handleNextAction}
                course={course}
              />
            ) : (
              <p className="text-lg text-center">No steps</p>
            )}
          </div>
        </CourseLeftLayout>

        <div className="w-full pt-[68px] hidden md:block sm:pt-[84px] pr-4 sm:pr-8">
          {loadingCourse ? (
            <Box className="h-full w-full flex items-center justify-center">
              <CircularProgress />
            </Box>
          ) : (
            <Chat
              handleNextAction={handleNextAction}
              messages={messages}
              isLoading={isLoading}
              setUserInput={setUserInput}
              setCompletedModal={setCompletedModal}
              handleSendMessage={handleSendMessage}
              userInput={userInput}
              activeStep={activeStep}
              title={`lesson ${activeStep} now playing`}
              setActiveStep={setActiveStep}
            />
          )}
        </div>

        <WarningAudio
          open={waringModal}
          handleCancel={() => navigate("/courses")}
          handleStartCourse={() => setWarningModal(false)}
        />

        <ExitCourseMessage
          open={exitModal}
          handleExitCourse={() => navigate("/courses")}
          handleCancel={() => setExitModal(false)}
        />

        <CourseCompleteMessage
          open={completedModal}
          rating={rating}
          setRating={setRating}
          loading={ratingLoading}
          handleBackDashboard={() => navigate("/courses")}
          handleFeedback={handleFeedback}
        />

        <Toast
          text={toastInfo.text}
          open={toastInfo.open}
          handleClose={handleCloseToast}
          severity={toastInfo.severity}
          timeHideDuration={toastInfo.timeHideDuration}
        />
      </div>
    </LayoutDashboard>
  );
};
