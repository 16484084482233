import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { CourseCard } from "src/components/Course";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { LayoutDashboard } from "src/components/Layouts/Dashboard";
import { getCoursesHaveJoined } from "src/redux/features/coursesEnrollmentsSlice";
import { Chat } from "src/components/Chat";
import { CourseSkeleton } from "src/components/Skeletons/CourseCard";
import { CourseLeftLayout } from "src/components/Course/CourseLeftLayout";
import { logOutReducer } from "src/redux/features/authSlice";
import { resetSession } from "src/redux/features/sessionSlice";
import { useNavigate } from "react-router-dom";

type Props = {};

function Courses(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
   const { data } = useAppSelector(
    (state) => state.courseJoinedState.coursesJoined
  );

  const session = useAppSelector((state) => state.sessionState.session);

  const messages = [
    {
      user: "Assistant",
      type: "text",
      text: "Please select from one of the following courses on the left hand side of the screen.",
    },
  ];

  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    const groupRoute = typeof window !== "undefined" && localStorage.getItem("group-route")
    
    session?.data &&
      dispatch(logOutReducer(session.data.fields?.session_id)).then((res: any) => {
        if (res?.payload?.message === "Log out successful") {
          dispatch(resetSession());
          navigate(`/${groupRoute ? groupRoute : "no-group"}/login`);

          if (typeof window !== "undefined") {
            localStorage.removeItem("access_token");
            localStorage.removeItem("skip-modal");
          }
        }
      });
  };

  useEffect(() => {
    dispatch(getCoursesHaveJoined()).then(() => setLoading(false));
  }, []);

  return (
    <LayoutDashboard handleExit={handleLogout}>
      <div className="flex justify-center w-full h-full gap-3 relative">
        <CourseLeftLayout titleBubble="enrolled courses" loading={loading}>
          {loading ? (
            <Box className="h-full w-full flex flex-col gap-3">
              {[1, 2, 3].map((item) => (
                <CourseSkeleton key={item} />
              ))}
            </Box>
          ) : data && data?.length > 0 ? (
            <div className="flex flex-col gap-3">
              {data?.map((record: any, index: number) => {
                return (
                  <CourseCard
                    key={index}
                    course={record?.fields}
                    timesComplete={Number(record?.fields?.times_complete) || 0}
                    currentStep={record?.fields?.current_step}
                    recordId={record?.fields?.enrollmentId}
                  />
                );
              })}
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center">
              <Typography color="text.secondary" className="text-24 my-24">
                No courses found!
              </Typography>
            </div>
          )}
        </CourseLeftLayout>

        <div className="w-full pt-[68px] hidden md:block sm:pt-[84px] pr-4 sm:pr-8">
          <Chat title={"SELECT A COURSE"} hideChat messages={messages} />
        </div>
      </div>
    </LayoutDashboard>
  );
}

export default Courses;