import React from "react";
import { ChevronIcon } from "../Icons";
import { CircularProgress } from "@mui/material";

type Props = {
  label: any;
  loading?: boolean;
  onClick: () => void;
};

export const ButtonModal = (props: Props) => {
  const { label, loading, onClick } = props;

  return (
    <div
      className="relative bg-white cursor-pointer overflow-hidden px-[24px] min-w-[192px] flex items-center justify-center py-[12px] rounded-[5px] border-1 border-solid border-black"
      onClick={onClick}
    >
      <ChevronIcon className="absolute" />
      {loading && (
        <CircularProgress
          className="w-[26px] h-[26px] mr-2"
          sx={{
            "& > svg": {
              width: "26px",
              height: "26px",
            },
            color: "#000",
          }}
        />
      )}
      <p className="z-10 text-[14px] font-light leading-[150%]">{label}</p>
    </div>
  );
};
