import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import * as yup from "yup";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { LayoutAuth } from "src/components/Layouts/Auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { InputField } from "src/components/Common/InputField";
import { AuthButton } from "src/components/Common/Button/AuthButton";

const defaultValues = {
  email: "",
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

const ForgotPasswordForm = () => {
  const navigate = useNavigate();

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  const groupInfo =
    localStorage.getItem("groupInfo") &&
    JSON.parse(localStorage.getItem("groupInfo") || "");

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (data: any) => {
    navigate(
      `/${groupInfo?.group_route}/check-your-email?email=${data?.email}`
    );
  };

  return (
    <form
      name="forgotPasswordForm"
      noValidate
      className="flex flex-col justify-center w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputField
            rest={{ ...field }}
            className="rounded-[8px]"
            type="email"
            placeholder="Enter your email"
            error={!!errors.email}
            helperText={errors?.email?.message}
          />
        )}
      />

      <AuthButton
        label={"Reset Password"}
        loading={loading}
        disabled={_.isEmpty(dirtyFields) || !isValid}
        className="mt-3"
      />

      <Typography
        className="text-center cursor-pointer text-[13px] mt-6 font-sans font-medium"
        onClick={() => {
          navigate(`/${groupInfo?.group_route}/login`);
        }}
      >
        <ArrowBackOutlinedIcon />
        Back to Login
      </Typography>
    </form>
  );
};

export const ForgotPassword = () => {
  return (
    <LayoutAuth
      label="Forgot password"
      slogan="No worries, we will send you reset instructions."
    >
      {<ForgotPasswordForm />}
    </LayoutAuth>
  );
};
