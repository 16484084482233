import { BodyGetDataFromVF } from "src/types/course";
import api from ".";

export const getCoursesJoined = () => {
    return api.get("/courses-enrollments");
};

export const getCourseJoinedById = (id: string) => {
    return api.get(`/courses-enrollments/${id}`)
}

export const updateUserRatingCourse = (id: string, body: any) => {
    return api.post(`/courses-enrollments/${id}`, body)
}

export const getDataFromVoiceFlowViaVersionId = (body: BodyGetDataFromVF) => {
    return api.post("/courses-enrollments/voice-flow", body)
}
