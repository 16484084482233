import React from "react";
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { MenuIcon } from "../Common/Icons";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { logOutReducer } from "src/redux/features/authSlice";
import { resetSession } from "src/redux/features/sessionSlice";

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name: string) => {
  if (!name) return null;

  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: "14px",
    },
    children: `${name.split(" ")[0][0]}`,
  };
};

type Props = {
  name: string;
  hideSettings?: boolean
  handleExpand?: () => void;
  handleExit: () => void;
};

const MenuButton = ({ title, handleClick, style }: any) => {
  return (
    <div
      onClick={handleClick}
      className={`${style} border-1 z-10 cursor-pointer border-solid text-[12px] bg-white border-black px-6 flex items-center justify-center h-[52px] uppercase`}
    >
      {title}
    </div>
  );
};

export const MenuHeader = (props: Props) => {
  const { name, handleExpand, handleExit, hideSettings = false } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.sessionState.session);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);

    const groupRoute = typeof window !== "undefined" && localStorage.getItem("group-route")
    data &&
      dispatch(logOutReducer(data.fields?.session_id)).then((res: any) => {
        if (res?.payload?.message === "Log out successful") {
          dispatch(resetSession());
          navigate(`/${groupRoute ? groupRoute : "no-group"}/login`);

          if (typeof window !== "undefined") {
            localStorage.removeItem("access_token");
            localStorage.removeItem("skip-modal");
          }
        }
      });
  };

  const settings = [
    {
      title: "Logout",
      handleClick: handleLogout,
    },
    {
      title: "Change Password",
      handleClick: () => {
        setAnchorElUser(null);
        navigate("/profile/change-password");
      },
    },
  ];

  return (
    <div className="w-full z-10 absolute left-1/2 transform -translate-x-1/2 px-4 top-[17px] sm:top-[33px] sm:px-8 max-w-[1440px]">
      <div className="flex px-3 items-start w-full justify-between">
        <div className="flex items-center gap-2">
          <MenuButton title={<MenuIcon />} handleClick={handleExpand} />
          {!hideSettings && (
            <MenuButton
              title={"SETTINGS"}
              handleClick={() => {
                console.log("SETTINGS");
              }}
              style={"hidden sm:flex"}
            />
          )}
          <MenuButton title={"EXIT"} handleClick={handleExit} />
        </div>
        <div className="grow-0 z-10">
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar {...stringAvatar(name)} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting.title} onClick={setting.handleClick}>
                <p className="text-center">{setting.title}</p>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </div>
  );
};
