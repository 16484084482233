import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormHelperText,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import _ from "lodash";
import { AuthBody } from "src/types/auth";
import React from "react";
import { Toast } from "src/components/Common/Toast";
import { ToastInfo } from "src/types/toast";
import { useAppDispatch } from "src/redux/store";
import { signUpReducer } from "src/redux/features/authSlice";
import { checkSessionReducer } from "src/redux/features/sessionSlice";
import { aboutMeViaEmail } from "src/redux/api/userApi";
import { LayoutAuth } from "src/components/Layouts/Auth";
import { InputField } from "src/components/Common/InputField";
import { Checkbox } from "src/components/Common/Checkbox";
import { AuthButton } from "src/components/Common/Button/AuthButton";

interface Props {}

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  firstName: yup.string().required("You must enter first name"),
  lastName: yup.string().required("You must enter last name"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(6, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match"),
  atLeast8Characters: yup
    .boolean()
    .oneOf([true], "Password is too short - should be 8 chars minimum."),
});

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  atLeast8Characters: false,
};

const SignUpForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [toastInfo, setToastInfo] = React.useState<ToastInfo>({
    text: "",
    open: false,
    severity: "warning",
  });

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setToastInfo({ ...toastInfo, open: false });
  };

  const { isValid, dirtyFields, errors } = formState;

  const groupInfo =
    localStorage.getItem("groupInfo") &&
    JSON.parse(localStorage.getItem("groupInfo") || "");

  const onSubmit = async (data: AuthBody) => {
    const { email, firstName, lastName, password } = data;
    setLoading(true);

    try {
      dispatch(signUpReducer({ email, password, firstName, lastName })).then(
        (res: any) => {
          setLoading(false);

          if (res?.payload) {
            setToastInfo({
              text: res?.payload?.message,
              open: true,
              severity: "success",
            });

            if (typeof window !== "undefined") {
              localStorage.setItem("registerEmail", res?.payload?.email);
              localStorage.setItem("access_token", res?.payload?.token);
              localStorage.removeItem("session_expired");
            }

            setTimeout(() => {
              navigate("/courses");
            }, 1500);
          } else {
            setLoading(false);
            setToastInfo({
              text: res?.error?.message,
              open: true,
              severity: "error",
            });
          }
        }
      );
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    setValue("email", searchParams.get("email") || "", {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [setValue]);

  return (
    <form
      name="registerForm"
      noValidate
      className="flex flex-col justify-center w-full gap-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <InputField
            className="rounded-[8px]"
            rest={{ ...field }}
            label="Email"
            type="email"
            error={!!errors.email}
            helperText={errors?.email?.message}
            value={searchParams.get("email") && searchParams.get("email")}
            disabled={!!searchParams.get("email")}
          />
        )}
      />

      <Controller
        name="firstName"
        control={control}
        render={({ field }) => (
          <InputField
            className="rounded-[8px]"
            rest={{ ...field }}
            autoFocus
            label="First Name"
            type="name"
            placeholder="Enter your first name"
            error={!!errors.firstName}
            helperText={errors?.firstName?.message}
          />
        )}
      />

      <Controller
        name="lastName"
        control={control}
        render={({ field }) => (
          <InputField
            className="rounded-[8px]"
            rest={{ ...field }}
            autoFocus
            label="Last Name"
            type="name"
            placeholder="Enter your last name"
            error={!!errors.lastName}
            helperText={errors?.lastName?.message}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <InputField
            className="rounded-[8px]"
            rest={{ ...field }}
            label="Password"
            type="password"
            placeholder="Create a password"
            error={!!errors.password}
            helperText={errors?.password?.message}
          />
        )}
      />
      <Controller
        name="passwordConfirm"
        control={control}
        render={({ field }) => (
          <InputField
            className="rounded-[8px]"
            rest={{ ...field }}
            label="Confirm Password"
            type="password"
            placeholder="Confirm your password"
            error={!!errors.passwordConfirm}
            helperText={errors?.passwordConfirm?.message}
          />
        )}
      />

      <Controller
        name="atLeast8Characters"
        control={control}
        render={({ field }) => {
          return (
            <FormControl
              className="w-full m-0"
              error={!!errors.atLeast8Characters}
            >
              <Checkbox label="Must be at least 8 characters" {...field} />
              {errors?.atLeast8Characters?.message && (
                <FormHelperText sx={{ margin: "8px 0px 0px" }}>
                  {errors?.atLeast8Characters?.message}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />

      <AuthButton
        label={"Get Started"}
        loading={loading}
        disabled={_.isEmpty(dirtyFields) || !isValid}
      />

      <Typography className="text-center text-[13px] font-sans font-medium">
        Already have an account?
        <Typography
          component={"span"}
          className="ml-1 cursor-pointer text-sm"
          onClick={() => {
            console.log({ groupInfo });
            navigate(`/${groupInfo?.group_route}/login`);
          }}
        >
          Login
        </Typography>
      </Typography>

      <Toast
        text={toastInfo.text}
        handleClose={handleClose}
        open={toastInfo.open}
        severity={toastInfo.severity}
        timeHideDuration={6000}
      />
    </form>
  );
};

export const SignUpPage = (props: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchDataAboutMe = async () => {
      await aboutMeViaEmail(searchParams.get("email") || "")
        .then((res: any) => {
          const groupRoute = res.user.fields?.group_route || "no-group";
          const userId = res.user.fields.user_id;

          if (userId && groupRoute) navigate(`/${groupRoute}/login`);
        })
        .catch((error: any) => {
          console.error(error);
        });
    };

    fetchDataAboutMe();
  }, []);

  return (
    <LayoutAuth
      label="SignUp"
      question="Already have an account?"
      goPage="login"
      nextPage="Sign in"
    >
      {<SignUpForm />}
    </LayoutAuth>
  );
};
