import * as React from "react";
import { StepperButton } from "./StepperButton";
import { StepperOrder } from "./StepperOrder";
import { StepperContent } from "./StepperContent";
import { AudioPlayer } from "../AudioPlayer";

export interface StepperProps {
  steps?: any;
  course?: any;
  messages?: any;
  activeStep: number;
  audioRef?: any;
  withoutAudio?: boolean;
  handleNextAction?: (data?: any) => void;
  handleSelectStep: (index: number, lesson: string) => void;
}

export const VerticalLinearStepper = (props: StepperProps) => {
  const {
    steps,
    messages,
    activeStep,
    handleSelectStep,
    audioRef,
    handleNextAction,
    withoutAudio = false,
    course,
  } = props;

  const [isPlaying, setIsPlaying] = React.useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="flex flex-col items-center w-full h-full gap-3">
      {steps &&
        steps.map((step: any, index: number) => {
          return (
            <div
              key={index}
              className={`flex w-full h-full relative z-100 flex-col sm:flex-row items-center gap-4 sm:gap-6 px-4 py-4 sm:px-6 border-1 border-solid border-black ${
                index < activeStep - 1 ? "bg-[#EAECF0]" : "bg-wite"
              }`}
            >
              {/* {!!course?.fields?.expert_sales ||
              (withoutAudio && (
                <div className="audio-container mt-2">
                  <AudioPlayer
                    ref={audioRef}
                    handleNextAction={handleNextAction}
                  />
                </div>
              ))} */}

              {activeStep === index + 1 && (
                <div className="absolute w-full h-full left-0 top-0 bottom-0">
                  <AudioPlayer
                    ref={audioRef}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    handleNextAction={handleNextAction}
                  />
                </div>
              )}

              <StepperOrder
                index={index}
                stepsLength={steps.length}
                activeStep={activeStep}
              />

              <div className="flex flex-col sm:flex-row z-100 items-center gap-5 w-full h-full">
                <StepperContent step={step} />

                <div className="h-full w-full sm:w-fit z-100">
                  <StepperButton label="play" onClick={togglePlayPause} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
