import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
} from "@mui/material";
import React from "react";
import * as yup from "yup";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { LayoutAuth } from "src/components/Layouts/Auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "src/components/Common/InputField";
import { Checkbox } from "src/components/Common/Checkbox";

const defaultValues = {
  password: "",
  passwordConfirm: "",
  atLeast8Characters: false,
};

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords must match"),
  atLeast8Characters: yup
    .boolean()
    .oneOf([true], "Password is too short - should be 8 chars minimum."),
});

const SetNewPasswordForm = () => {
  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (data: any) => {
    setLoading(true);
  };

  return (
    <form
      name="resetPasswordForm"
      noValidate
      className="flex flex-col justify-center w-full gap-[24px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <InputField
            rest={{ ...field }}
            label="Password"
            type="password"
            placeholder="Enter your password"
            error={!!errors.password}
            helperText={errors?.password?.message}
          />
        )}
      />
      <Controller
        name="passwordConfirm"
        control={control}
        render={({ field }) => (
          <InputField
            rest={{ ...field }}
            label="Confirm Password"
            type="password"
            placeholder="Confirm your password"
            error={!!errors.passwordConfirm}
            helperText={errors?.passwordConfirm?.message}
          />
        )}
      />

      <Controller
        name="atLeast8Characters"
        control={control}
        render={({ field }) => {
          return (
            <FormControl
              className="w-full m-0"
              error={!!errors.atLeast8Characters}
            >
              <Checkbox label="Must be at least 8 characters" {...field} />
              {errors?.atLeast8Characters?.message && (
                <FormHelperText sx={{ margin: "8px 0px 0px" }}>
                  {errors?.atLeast8Characters?.message}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />

      <Button
        variant="contained"
        color="secondary"
        className={`w-full rounded-[8px] ${
          !(_.isEmpty(dirtyFields) || !isValid) && "bg-[#bacacf]"
        } text-[15px] text-black normal-case`}
        aria-label="Set New Password"
        disabled={_.isEmpty(dirtyFields) || !isValid}
        type="submit"
        size="large"
      >
        {loading ? (
          <Box className="flex items-center gap-2">
            Loading
            <CircularProgress
              className="w-[26px] h-[26px]"
              sx={{
                "& > svg": {
                  width: "26px",
                  height: "26px",
                },
                color: "#fff",
              }}
            />
          </Box>
        ) : (
          "Set New Password"
        )}
      </Button>
    </form>
  );
};

export const SetNewPassword = () => {
  return (
    <LayoutAuth
      label="set new password"
      slogan="Your new password must be different to
  previously used passwords."
    >
      {<SetNewPasswordForm />}
    </LayoutAuth>
  );
};
