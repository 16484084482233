import { Typography } from "@mui/material";
import React from "react";

interface ProgressCSSProps extends React.CSSProperties {
  "--progress-width": number;
  "--buffered-width": number;
}

interface AudioProgressBarProps
  extends React.ComponentPropsWithoutRef<"input"> {
  duration: number;
  currentProgress: number;
  buffered: number;
  durationDisplay: string;
  elapsedDisplay: string;
}

export const AudioProgressBar = (props: AudioProgressBarProps) => {
  const {
    duration,
    currentProgress,
    buffered,
    durationDisplay,
    elapsedDisplay,
    ...rest
  } = props;

  const progressBarWidth = isNaN(currentProgress / duration)
    ? 0
    : currentProgress / duration;
  const bufferedWidth = isNaN(buffered / duration) ? 0 : buffered / duration;

  const progressStyles: ProgressCSSProps = {
    "--progress-width": progressBarWidth,
    "--buffered-width": bufferedWidth,
  };

  return (
    <div className="flex items-center gap-2 flex-1 h-full">
      {/* <Typography className="text-black text-base leading-none">
        {elapsedDisplay}

      </Typography> */}

      <div className="relative w-full h-full group">
        <input
          type="range"
          name="progress"
          className={`progress-bar absolute inset-0 w-full m-0 h-full bg-transparent appearance-none cursor-pointer transition-all accent-black hover:accent-black before:absolute before:inset-0 before:h-full before:w-full before:origin-left after:absolute after:h-full after:w-full`}
          style={progressStyles}
          min={0}
          max={duration.toFixed()}
          value={currentProgress.toFixed()}
          {...rest}
        />
      </div>
      {/* <Typography className="text-black text-base leading-none">
        {durationDisplay}
      </Typography> */}
    </div>
  );
};
