import React from "react";
import { TitleBubble } from "src/components/TitleBubble";

type Props = {
  open: boolean;
  children: JSX.Element;
  sx?: any;
  className?: string;
  customStyle?: string;
  height?: number;
  title?: string;
  handleClose?: () => void;
};

export const Modal = (props: Props) => {
  const {
    children,
    open,
    handleClose,
    sx,
    className,
    customStyle,
    height,
    title,
  } = props;

  return (
    <div className="absolute">
      {open ? (
        <div onClick={handleClose}>
          <div
            className={`justify-center transition-all ${
              open ? "scale-100 opacity-100" : "scale-125 opacity-0"
            } items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none `}
          >
            <div
              className={`relative w-auto my-6 mx-auto ${className} m-auto`}
            >
              {/*content*/}
              <div className="border-1 border-solid border-black relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <TitleBubble
                  title={title}
                  height={height}
                  customStyle={customStyle}
                />

                {children}

                <div className="w-full h-[4px] border-y-[1px] divide-solid border-black"></div>
                <div className="w-full h-[14px] bg-[#BAC9CF]"></div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </div>
  );
};
