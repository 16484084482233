import React from "react";
import { Modal } from "../../Common/Modal";
import { WarningIcon } from "src/components/Common/Icons";
import { FooterModal } from "src/components/Common/Modal/Footer";
import { ButtonModal } from "src/components/Common/Modal/Button";


type Props = {
  open: boolean;
  handleExitCourse: () => void;
  handleCancel: () => void;
};

export const ExitCourseMessage = (props: Props) => {
  const { open, handleCancel, handleExitCourse } = props;

  return (
    <Modal
      title="Get Ready"
      open={open}
      height={56}
      customStyle={"p-[16px] sm:px-[76px]"}
      className="max-w-[328px] sm:max-w-[448px]"
    >
      <div>
        <div className="px-[56px] pt-[48px] pb-[88px] gap-8 flex flex-col items-center justify-center">
          <WarningIcon />

          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-center text-[14px] leading-[150%] font-light">
              Please confirm that you would like to exit the course and return
              to the dashboard.
            </p>

            <p className="text-center text-[14px] leading-[150%] font-light">Your progress will be saved.</p>
          </div>
        </div>
        <FooterModal>
          <div className="flex flex-col items-center justify-center gap-2">
            <ButtonModal onClick={handleExitCourse} label={"Exit Course"} />
            <ButtonModal onClick={handleCancel} label={"Cancel"} />
          </div>
        </FooterModal>
      </div>
    </Modal>
  );
};
