import { Box, Button, CircularProgress, TextField } from "@mui/material";
import * as yup from "yup";
import _ from "lodash";
import React from "react";
import { LayoutDashboard } from "src/components/Layouts/Dashboard";
import { Controller, useForm } from "react-hook-form";
import { Toast } from "src/components/Common/Toast";
import { ToastInfo } from "src/types/toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "src/redux/store";
import { getInfoAboutMe } from "src/redux/features/authSlice";
import { ChangePasswordBody } from "src/types/auth";
import { changePassword } from "src/redux/api/authApi";

type Props = {};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  current_password: yup
    .string()
    .required("Please enter your current password."),
  new_password: yup
    .string()
    .required("Please enter your new password.")
    .min(6, "New password is too short - should be 6 chars minimum."),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), undefined], "Passwords must match"),
});

const defaultValues = {
  email: "",
  current_password: "",
  new_password: "",
  confirm_password: "",
};

export const ChangePassword = (props: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authState.auth) || null;

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [toastInfo, setToastInfo] = React.useState<ToastInfo>({
    text: "",
    open: false,
    severity: "warning",
  });

  const [loading, setLoading] = React.useState<boolean>(false);

  const { isValid, dirtyFields, errors } = formState;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setToastInfo({ ...toastInfo, open: false });
  };

  const onSubmit = async (data: ChangePasswordBody) => {
    setLoading(true);
    try {
      await changePassword(data)
        .then((res: any) => {
          setLoading(false);

          setToastInfo({
            text: res?.message,
            open: true,
            severity: "success",
          });
        })
        .catch((error) => {
          setLoading(false);

          setToastInfo({
            text: error?.message,
            open: true,
            severity: "error",
          });
        });
    } catch (error) {
      console.log({ error });
    }
  };

  React.useEffect(() => {
    setValue("email", (user && user?.fields?.email) || "", {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [setValue, user]);

  React.useEffect(() => {
    try {
      dispatch(getInfoAboutMe());
    } catch (error) {
      console.log({ error });
    }
  }, []);

  return (
    <LayoutDashboard handleExit={() => console.log("exitingggg")}>
      <Box className="w-full h-full flex flex-col items-center justify-center">
        <p className="font-semibold text-4xl mb-10">Change Password</p>
        <form
          name="loginForm"
          noValidate
          className="flex flex-col justify-center w-[350px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-6 text-sm"
                label="Email"
                autoFocus
                type="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                value={user?.fields?.email}
                variant="outlined"
                required
                fullWidth
                disabled
              />
            )}
          />

          <Controller
            name="current_password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-6 text-sm"
                label="Current Password"
                type="password"
                error={!!errors.current_password}
                helperText={errors?.current_password?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="new_password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-6 text-sm"
                label="New Password"
                type="password"
                error={!!errors.new_password}
                helperText={errors?.new_password?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-6 text-sm"
                label="Confirm Password"
                type="password"
                error={!!errors.confirm_password}
                helperText={errors?.confirm_password?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Button
            variant="contained"
            color="secondary"
            className={`w-full rounded-[28px] ${
              !(_.isEmpty(dirtyFields) || !isValid) && "bg-[#4f46e5]"
            } text-[15px] normal-case`}
            aria-label="Change Password"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
            size="large"
          >
            {loading ? (
              <Box className="flex items-center gap-2">
                Loading
                <CircularProgress
                  className="w-[26px] h-[26px]"
                  sx={{
                    "& > svg": {
                      width: "26px",
                      height: "26px",
                    },
                    color: "#fff",
                  }}
                />
              </Box>
            ) : (
              "Change Password"
            )}
          </Button>

          <Toast
            text={toastInfo.text}
            handleClose={handleClose}
            open={toastInfo.open}
            severity={toastInfo.severity}
            timeHideDuration={6000}
          />
        </form>
      </Box>
    </LayoutDashboard>
  );
};
