import React from "react";
import { Modal } from "../../Common/Modal";
import { WarningIcon } from "src/components/Common/Icons";
import { FooterModal } from "src/components/Common/Modal/Footer";
import { ButtonModal } from "src/components/Common/Modal/Button";

type Props = {
  open: boolean;
  handleStartCourse: () => void;
  handleCancel: () => void;
};

export const WarningAudio = (props: Props) => {
  const { open, handleCancel, handleStartCourse } = props;

  return (
    <Modal
      title="Get Ready"
      open={open}
      height={56}
      customStyle={"p-[16px] sm:px-[76px]"}
      className="max-w-[328px] sm:max-w-[448px]"
    >
      <div>
        <div className="px-[56px] pt-[48px] pb-[88px] gap-8 flex flex-col items-center justify-center">
          <WarningIcon />

          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-center text-[14px] leading-[150%] font-light">This is an audio experience.</p>

            <p className="text-center text-[14px] leading-[150%] font-light">
              Please make sure you have your headphones or speakers at the
              ready.
            </p>
          </div>
        </div>
        <FooterModal>
          <div className="flex flex-col items-center justify-center gap-2">
            <ButtonModal onClick={handleStartCourse} label={"Start Course"} />
            <ButtonModal onClick={handleCancel} label={"Cancel"} />
          </div>
        </FooterModal>
      </div>
    </Modal>
  );
};
