import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_URL;

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("access_token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  async (response) => {
    return response.data || response;
  },
  async (error) => {
    console.log({ error });
    throw error?.response?.data || error?.response || error || null;
  }
);

export default api;
