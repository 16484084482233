import { BodySession } from 'src/types/session';
import api from '.';

export const checkSession = () => {
    return api.post("/sessions")
}

export const getSession = () => {
    return api.get("/sessions")
}

export const patchSession = (body: BodySession) => {
    return api.patch("/sessions", body)
}