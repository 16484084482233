import { Skeleton } from "@mui/material";
import { StepperContent } from "../Stepper/StepperContent";

export const StepperSkeleton = () => {
  return (
    <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-6 px-4 py-4 sm:px-6 border-1 border-solid border-black bg-white">
      <div><Skeleton variant="circular" width={40} height={40} /></div>

      <div className="flex flex-col sm:flex-row items-center gap-5 w-full h-full">
        <StepperContent loading />

        <div className="h-full w-full sm:w-fit">
          <Skeleton variant="rectangular" className="w-[128px] h-[128px]" />
        </div>
      </div>
    </div>
  );
};
