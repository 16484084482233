import { useNavigate } from "react-router-dom";
// import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import React from "react";
import { CourseInfo } from "./CourseCard";
import { CourseButton } from "./CourseButton";
import { CourseDetailsMessage } from "../Popup/CourseDetails";

type Props = {
  course?: any;
  recordId: string;
  currentStep: number;
  timesComplete: number;
};

export const CourseCard = (props: Props) => {
  const { course, currentStep, timesComplete, recordId } = props;
  const navigate = useNavigate();

  const [openDetails, setOpenDetails] = React.useState(false);

  return (
    <>
      <div className="flex flex-col items-stretch sm:flex-row bg-[#bacacf] border-1 border-solid border-black rounded-[14px] px-3 pt-[18px] pb-3 gap-3 w-full">
        <CourseInfo
          course={course}
          currentStep={currentStep}
          timesComplete={timesComplete}
        />

        <div className="flex flex-row sm:flex-col w-full sm:w-fit gap-3">
          <CourseButton
            label={"start course"}
            onClick={() => {
              if (typeof window !== "undefined") {
                localStorage.setItem("recordId", recordId);
                localStorage.setItem("courseId", course?.course_id);
              }

              navigate(`${course?.version_id}?course-id=${course?.course_id}`);
            }}
          />

          <CourseButton
            label={"details"}
            onClick={() => setOpenDetails(true)}
          />
        </div>
      </div>

      <CourseDetailsMessage
        course={course}
        open={openDetails}
        handleExitMessage={() => setOpenDetails(false)}
      />
    </>
  );
};
