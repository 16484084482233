import React from "react";

type Props = {
  label: string;
  onClick: () => void;
};

export const CourseButton = (props: Props) => {
  const { label, onClick } = props;

  return (
    <div
      className="rounded-[14px] min-w-[128px] z-10 cursor-pointer bg-[#BACACF] border-1 border-solid border-black p-2 h-full w-full"
      onClick={onClick}
    >
      <div className="rounded-[8px] p-2 flex items-center justify-center bg-white border-1 border-solid min-h-[80px] h-full border-black">
        <p className="text-center text-[14px] font-light leading-[150%] uppercase">{label}</p>
      </div>
    </div>
  );
};
