import React from "react";

interface AssistantMessage {
  type: string;
  payload?: any;
}

export const getAssistantMessages = (data: any) => {
  if (!Array.isArray(data) && data?.length < 0) return [];

  const messages = data
    .map((item: AssistantMessage) => {
      if (item.type === "visual") {
        return {
          user: "Assistant",
          type: "visual",
          payload: item.payload,
        };
      } else if (item.type === "Audio" || item.type === "audio") {
        return { user: "Assistant", type: "audio", src: item.payload };
      } else if (item.type === "text") {
        console.log("Text payload:", item.payload);
        return {
          user: "Assistant",
          type: "text",
          text: item.payload.message,
        };
      } else if (item.type === "choice") {
        console.log("Choice payload:", item.payload);
        return {
          user: "Assistant",
          type: "choice",
          choices: item.payload.buttons.map((button: any) => ({
            name: button.name,
            request: button.request,
          })),
        };
      } else if (item.type === "cardV2") {
        console.log("cardV2 payload:", item.payload);
        return {
          user: "Assistant",
          type: "cardV2",
          title: item.payload.title,
          description: item.payload.description,
          imageUrl: item.payload.imageUrl,
          buttons: Array.isArray(item.payload.buttons)
            ? item.payload.buttons.map((button: any) => ({
                name: button.name,
                request: button.request,
              }))
            : [],
        };
      }
      return null; // Add this line to capture object types not supported or unusual payloads for a object type
    })
    .filter((item: any) => item);

  return messages;
};

export const formatDurationDisplay = (duration: number) => {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);

  const formatted = [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":");

  return formatted;
};

export const convertToMinutes = (seconds: number) => {
  const min = Math.floor(seconds / 60);

  return min;
};

export const getWidthProgress = (decimal: number) => {
  if(decimal === 0) return 'w-1';
  if(decimal === 1) return 'w-[99%]';
  return `w-[${Math.round(decimal * 100)}%]`
};

export const formatText = (text: string, isDescription: boolean) => {
  // Replace line breaks with <br> tags
  let formattedText;
  if(isDescription) formattedText = text.replace(/\n\n/g, "<br>")
  else formattedText = text.replace(/\n\n/g, "<br><br>").replace(/\n/g, "<br>");

  return { __html: formattedText };
}