import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { checkSession, getSession } from "../api/sessionApi"
import { AppState } from "../store";
import { get } from "lodash";

const initialState = {
    session: {} as any,
    status: "loading",
}

export const checkSessionReducer = createAsyncThunk("/session-post", async () => {
    try {
        const res = await checkSession();
        return res;
    } catch (error) {
        return error
    }
})

export const getSessionReducer = createAsyncThunk("/session-get", async () => {
    try {
        const res = await getSession();
        return res;
    } catch (error) {
        return error
    }
})

export const sessionSlice = createSlice({
    initialState,
    name: "session",
    reducers: {
        setSession: (state, action) => {
            state.session = action.payload;
        },
        resetSession: (state) => {
            state.session = {}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkSessionReducer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(checkSessionReducer.rejected, (state) => {
                state.status = "idle"
                state.session = {}
            })
            .addCase(checkSessionReducer.fulfilled, (state, action) => {
                state.status = "idle"
                state.session = action.payload
            })
            .addCase(getSessionReducer.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSessionReducer.fulfilled, (state, action) => {
                state.status = "idle"
                state.session = action.payload
            })
    },
})

export const { resetSession, setSession } = sessionSlice.actions;

export const getSessionUser = (state: AppState) => get(state, "session")

export default sessionSlice.reducer