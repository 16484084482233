import React from "react";
import { Skeleton } from "@mui/material";
import { CourseHeaderIcon } from "../Common/Icons";
import { CourseInfoSkeleton } from "./CourseInfo";

type Props = {};

export const CourseSkeleton = (props: Props) => {
  return (
    <>
      <div className="flex flex-col items-stretch sm:flex-row bg-[#bacacf] border-1 border-solid border-black rounded-[14px] px-3 pt-[18px] pb-3 gap-3 w-full">
        <div
          className={`w-full bg-white border-1 border-solid border-black rounded-[8px] relative`}
        >
          <CourseHeaderIcon className="absolute left-1/2 transform -translate-x-1/2 top-[-10px]" />

          <CourseInfoSkeleton />
        </div>

        <div className="flex flex-row sm:flex-col w-full sm:w-fit gap-3">
          <Skeleton variant="rectangular" className="w-[128px] h-[128px]" />

          <Skeleton variant="rectangular" className="w-[128px] h-[128px]" />
        </div>
      </div>
    </>
  );
};
