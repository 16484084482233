import { AlertProps, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  open: boolean;
  text: string;
  severity: "success" | "error" | "warning" | "info";
  timeHideDuration?: number | null;
  handleClose?: () => void;
};

export const Toast = (props: Props) => {
  const { open, text, severity, timeHideDuration, handleClose } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={timeHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {text}
      </Alert>
    </Snackbar>
  );
};
