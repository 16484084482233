import React from "react";
import { ArrowRightIcon } from "../Common/Icons";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

interface ChatInputProps {
  value?: string;
  placeholder?: string;
  type?: string;
  onKeyDown: (data: any) => void;
  onChange: (data: any) => void;
}

export const ChatInput = (props: ChatInputProps) => {
  const { value, placeholder, type, onChange, onKeyDown } = props;

  return (
    <div className="flex bg-white justify-between items-center border-t-1 border-solid border-black py-3 px-6">
      <input
        className="h-[68px] text-black text-sm leading-[150%] flex-1 font-normal"
        value={value}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />

      {/* <ArrowRightIcon /> */}
      <ArrowForwardRoundedIcon className="w-[48px] h-[48px]" />
    </div>
  );
};
