import { Skeleton } from "@mui/material";
import React from "react";

interface StepperContentProps {
  step?: any;
  loading?: boolean;
}

export const StepperContent = (props: StepperContentProps) => {
  const { step, loading } = props;

  return (
    <div className="flex flex-col gap-3 flex-1 w-full">
      <div className="border-1 border-solid border-black rounded-[8px] px-5 py-6">
        {loading ? (
          <Skeleton variant="text" className="w-full text-lg" />
        ) : (
          <p className="text-[14px] font-light leading-[150%]">{step.title}</p>
        )}
      </div>
      <div className="flex items-center w-full gap-1">
        <div className="border-1 border-black min-h-[44px] px-2 border-solid rounded-full w-fit min-w-[104px] flex items-center jusitify-center h-full">
          {loading ? (
            <Skeleton variant="text" className="w-full text-lg" />
          ) : (
            <p className="text-[12px] font-light leading-[150%] text-center w-full">
              2:32 Mins
            </p>
          )}
        </div>
        <div className="border-1 border-black min-h-[44px] px-2 border-solid rounded-full w-full flex items-center jusitify-center h-full">
          {loading ? (
            <Skeleton variant="text" className="w-full text-lg" />
          ) : (
            <p className="text-[12px] font-light leading-[150%] text-center w-full">
              Facilitator: Dr Jennifer Whelan
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
