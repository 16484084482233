import { Skeleton } from "@mui/material";
import React from "react";
import { WaveBackground } from "../Common/Icons";

type Props = {};

export const CourseInfoSkeleton = (props: Props) => {
  return (
    <div className="flex flex-col relavite overflow-hidden">
        <WaveBackground className="absolute" />

      <div className="pt-12 pb-7 px-8 flex flex-col gap-2">
        <Skeleton variant="text" className="w-full text-lg" />
        <Skeleton variant="text" className="w-full text-lg" />
        <Skeleton variant="text" className="w-full text-lg" />
      </div>

      <div className="p-6 flex items-center justify-end gap-3">
        <div className="flex gap-2 items-center">
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="text" className="w-[80px] text-lg" />
        </div>

        <div className="flex gap-2 items-center">
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="text" className="w-[80px] text-lg" />
        </div>
      </div>
    </div>
  );
};
