import React from "react";

interface StepperOrderProps {
  index: number;
  stepsLength: number;
  activeStep: number;
}

export const StepperOrder = (props: StepperOrderProps) => {
  const { index, stepsLength, activeStep } = props;

  return (
    <div className="flex flex-col justify-center h-full items-center relative">
      <div
        className={`block sm:hidden w-px h-[28px] z-10 ${
          index <= 0 ? "opacity-0" : "bg-black"
        } absolute top-[-28px]`}
      />

      <div>
        <div
          className={`flex items-center justify-center border-1 border-solid border-black w-10 h-10 ${
            index + 1 <= activeStep
              ? "bg-black text-white"
              : "bg-white text-black"
          } text-xs font-medium border rounded-full`}
        >
          {index + 1}
        </div>
      </div>
      <div
        className={`w-px h-[16px] sm:h-[108px] z-10 ${
          index === stepsLength - 1
            ? "opacity-100 sm:opacity-0 bg-black"
            : "bg-black"
        } absolute bottom-[-15px] sm:bottom-[-120px]`}
      />
    </div>
  );
};
