import { Box } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  text: string;
  className?: string;
  sx?: any;
  endIcon?: ReactNode;
  handleClick: () => void;
};

export const Button = (props: Props) => {
  const { className, text, sx, endIcon, handleClick } = props;

  return (
    <Box
      sx={{
        border: "1px solid #d9dde3",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        ...sx,
      }}
      onClick={handleClick}
      className={`cursor-pointer text-black font-semibold text-base capitalize flex items-center justify-center px-[18px] py-[10px] w-[170px] rounded-lg ${className}`}
    >
      {text}
    </Box>
  );
};
