import React from "react";
import { Modal } from "../Common/Modal";
import { Box, Stack, Typography } from "@mui/material";
import { Button } from "../Common/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const Tips = [
  "This application is an audio experience have your headphones ready.",
  "You can take breaks and return to your current lesson.",
  "You have the opportunity to ask questions and receive real time responses.",
  "If you wish to download your course transcript you can do so by hitting the transcript button.",
];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: 400,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow:
    "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
  overflow: "hidden",
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dotsClass: `slick-dots static slick-thumb custom-dots flex items-center justify-center gap-1 mb-8 mt-5`,
};

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const StartUpTips = (props: Props) => {
  const { open, handleClose } = props;
  const slider = React.useRef(null) as any;

  return (
    <Modal open={open} handleClose={handleClose}>
      <Box sx={style}>
        <img
          className="w-full "
          src="/assets/images/img-tips.png"
          alt="image tips"
        />

        <Stack className="flex flex-col items-center justify-center w-full p-6">
          <Typography className="font-semibold text-lg mb-1">
            Before You Get Started
          </Typography>

          <Slider
            ref={slider}
            {...settings}
            className="w-full flex flex-col items-center justify-center custom-slick-list"
          >
            {Tips.map((tip, index) => (
              <Typography
                key={index}
                className="text-center text-sm text-[#475467] font-normal"
              >
                {tip}
              </Typography>
            ))}
          </Slider>

          <Stack direction={"row"} gap="12px">
            <Button text={"Skip"} handleClick={handleClose} />
            <Button
              text={"Continue"}
              handleClick={() => slider?.current?.slickNext()}
              sx={{ border: "1px solid #7f56d9" }}
              className="text-white bg-[#7f56d9]"
            />
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
