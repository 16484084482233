import React from "react";

interface CheckboxProps {
  label?: string;
  value?: any;
  onChange?: () => void
}

export const Checkbox = (props: CheckboxProps) => {
  const { label, value, onChange  } = props;

  return (
    <div className="inline-flex items-center gap-2">
      <label
        className="relative flex items-center rounded-full cursor-pointer"
        data-ripple-dark="true"
        htmlFor="checkbox"
      >
        <input
          type="checkbox"
          className="before:content[''] peer relative h-[20px] w-[20px] cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-[20px] before:w-[20px] before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#DBE4E6] checked:bg-[#DBE4E6] checked:before:bg-[#DBE4E6] hover:before:opacity-10"
          id="checkbox"
          value={value}
          onChange={onChange}
        />
        <div className="text-white transition-opacity opacity-0 pointer-events-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 peer-checked:opacity-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3.5 w-3.5"
            viewBox="0 0 20 20"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
      </label>

      {label && <p>{label}</p>}
    </div>
  );
};
