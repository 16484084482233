import React, { useState } from "react";
import { CourseHeader } from "./CourseHeader";
import { TitleBubble } from "../TitleBubble";
import "./index.css";
import { useAppSelector } from "src/redux/store";

type Props = {
  children: any;
  course?: any;
  titleBubble: string;
  loading?: boolean;
};

export const CourseLeftLayout = (props: Props) => {
  const { children, course, titleBubble, loading } = props;

  const { user } = useAppSelector((state) => state.authState.auth) || null;

  const [userInfo, setUserInfo] = useState() as any;

  React.useEffect(() => {
    user && setUserInfo({ ...user.fields });
  }, [user]);

  return (
    <div className="background-dots w-full">
      <div className="flex flex-col w-full gap-8 pr-0 sm:pr-3 md:pr-0 pt-[68px] sm:pt-[84px] pl-4 sm:pl-8 max-h-[100vh]">
        <div className="pr-4 sm:pr-5 md:pr-[22px] gap-8 flex flex-col">
          <CourseHeader
            name={userInfo?.first_name}
            stakeholderWelcome={userInfo?.stakeholder_welcome}
            coursesCompleted={userInfo?.total_courses_complete}
            lessonsCompleted={userInfo?.total_lessons_complete}
            course={course}
            loading={loading}
            bodyMessage={userInfo?.stakeholder_body_message}
          />

          <TitleBubble
            title={titleBubble}
            height={20}
            customStyle={"border-1"}
            styleBuddle="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </div>

        <div className="flex scroller flex-col w-full h-full pr-4 sm:pr-[10px] md:pr-3 pb-2 md:pb-0">
          {children}
        </div>
      </div>
    </div>
  );
};
