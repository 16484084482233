import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logOut, signIn, signUp } from "../api/authApi";
import { get } from "lodash";
import { AppState } from "../store";
import { aboutMe } from "../api/userApi";

const initialState = {
  auth: {} as any,
  status: "loading",
  message: "",
};

export const signUpReducer = createAsyncThunk(
  "auth/sign-up",
  async (props: any) => {
    try {
      const response = await signUp(props);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const signInReducer = createAsyncThunk(
  "auth/sign-in",
  async (props: any) => {
    try {
      const res = await signIn(props);
      return res;
    } catch (error) {
      throw error;
    }
  }
);

export const logOutReducer = createAsyncThunk("auth/log-out", async (sessionId: string) => {
  try {
    const res = await logOut({ sessionId });
    return res;
  } catch (error) {
    throw error;
  }
})

export const getInfoAboutMe = createAsyncThunk("/me", async () => {
  try {
    const res = await aboutMe();
    return res;
  } catch (error) {
    // throw error;
    return error
  }
});

export const userSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    logout: () => initialState,
    setUser: (state, action) => {
      state.auth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpReducer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signInReducer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInfoAboutMe.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logOutReducer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logOutReducer.fulfilled, (state) => {
        state.auth = {}
        state.status = "idle"
      })
      .addCase(getInfoAboutMe.fulfilled, (state, action) => {
        state.status = "idle";
        state.auth = action.payload;
      })
      .addCase(getInfoAboutMe.rejected, (state, action) => {
        state.status = "idle";
        state.auth = {};
      });
  },
});

export const { logout, setUser } = userSlice.actions;

export const getUser = (state: AppState) => get(state, "auth");

export default userSlice.reducer;
