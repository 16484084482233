import { Box } from "@mui/material";
import React from "react";
import { MenuHeader } from "src/components/MenuHeader";
import { StartUpTips } from "src/components/StartUpTips";
import { getInfoAboutMe } from "src/redux/features/authSlice";
import { checkSessionReducer } from "src/redux/features/sessionSlice";
import { useAppDispatch, useAppSelector } from "src/redux/store";

type Props = {
  children?: JSX.Element;
  showTips?: boolean;
  hideSettings?: boolean;
  handleExpand?: () => void;
  handleExit: () => void;
};

export const LayoutDashboard = (props: Props) => {
  const {
    children,
    showTips,
    hideSettings = false,
    handleExpand,
    handleExit,
  } = props;

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.authState.auth) || null;

  const [name, setName] = React.useState("");
  const [openTips, setOpenTips] = React.useState(true);

  const handleCloseModal = () => {
    setOpenTips(false);
    typeof window !== "undefined" && localStorage.setItem("skip-modal", "true");
  };

  React.useEffect(() => {
    try {
      dispatch(getInfoAboutMe());
    } catch (error) {
      console.log({ error });
    }
  }, []);

  React.useEffect(() => {
    user?.fields?.group_route &&
      typeof window !== "undefined" &&
      localStorage.setItem("group-route", user?.fields?.group_route);
    user && setName(user?.fields?.first_name);
  }, [user]);

  React.useEffect(() => {
    dispatch(checkSessionReducer());
  }, []);

  return (
    <div className="w-full bg-white flex flex-col">
      <MenuHeader
        name={name}
        hideSettings={hideSettings}
        handleExpand={handleExpand}
        handleExit={handleExit}
      />

      {showTips &&
        typeof window !== "undefined" &&
        !localStorage.getItem("skip-modal") && (
          <StartUpTips open={openTips} handleClose={handleCloseModal} />
        )}

      <div className="h-full max-h-[100vh] max-w-[1440px] w-full m-auto">
        {children}
      </div>
    </div>
  );
};
