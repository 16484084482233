import React from "react";
import { LayoutAuth } from "src/components/Layouts/Auth";
import { Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { AuthButton } from "src/components/Common/Button/AuthButton";

export const CheckYourEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const groupInfo =
    localStorage.getItem("groupInfo") &&
    JSON.parse(localStorage.getItem("groupInfo") || "");

  return (
    <LayoutAuth
      label="check your email"
      slogan={`We sent a password reset link to ${
        searchParams.get("email") && searchParams.get("email")
      }`}
    >
      <div className="flex flex-col justify-center w-full gap-6">
        <AuthButton label={"Open Email App"} />
        <Typography
          className="text-center cursor-pointer text-[13px] font-sans font-medium"
          onClick={() => {
            navigate(`/${groupInfo?.group_route}/login`);
          }}
        >
          <ArrowBackOutlinedIcon />
          Back to Login
        </Typography>
      </div>
    </LayoutAuth>
  );
};
