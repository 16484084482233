import { Store, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authSlice from "./features/authSlice";
import courseJoinedSlice from "./features/coursesEnrollmentsSlice";
import sessionSlice from "./features/sessionSlice";

const reducer = {
  courseJoinedState: courseJoinedSlice,
  authState: authSlice,
  sessionState: sessionSlice,
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<Store["getState"]>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
