import React from "react";

type Props = {
  children: any;
};

export const FooterModal = (props: Props) => {
  const { children } = props;
  return (
    <div className="border-t-1 border-solid border-black bg-[#f6f8f8] py-[24px]">
      {children}
    </div>
  );
};
