import React from "react";
import { Modal } from "../../Common/Modal";
import {
  CompleteMessageIcon,
  WaveBackground,
} from "src/components/Common/Icons";
import { FooterModal } from "src/components/Common/Modal/Footer";
import { ButtonModal } from "src/components/Common/Modal/Button";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import "./index.css";
import { formatText } from "src/helper";

type Props = {
  open: boolean;
  bodyMessage: any;
  handleExitMessage: () => void;
};

export const ClientMessageToClients = (props: Props) => {
  const { open, bodyMessage, handleExitMessage } = props;

  return (
    <Modal
      title="stakeholder message"
      open={open}
      height={80}
      customStyle={"p-[16px] sm:px-[192px]"}
      className="max-w-[328px] sm:max-w-[680px]"
    >
      <div>
        <div className="px-4 sm:px-3 pt-[88px] relative">
          <WaveBackground className="absolute w-full h-full top-0 right-0 left-0" />

          <div className="scroller overflow-y-auto px-[50px] sm:px-[44px] max-h-[397px] z-10 h-full w-full relative">
            <div className="mb-[88px]">
              {bodyMessage && (
                <div
                  className="text-[14px] leading-[150%] font-light"
                  dangerouslySetInnerHTML={formatText(bodyMessage, false)}
                ></div>
              )}
            </div>
          </div>
        </div>
        <FooterModal>
          <div className="flex items-center justify-center">
            <ButtonModal onClick={handleExitMessage} label={"Exit Message"} />
          </div>
        </FooterModal>
      </div>
    </Modal>
  );
};
