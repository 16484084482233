import React, { useState, useRef, useEffect } from "react";
import { forwardRef } from 'react'
import { VolumeInput } from "./Volum";
import { AudioProgressBar } from "./AudioProgessBar";
import { formatDurationDisplay } from "src/helper";
import { IconButton } from "@mui/material";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export interface AudioPlayerProps {
    isPlaying?: boolean;
    handleNextAction?: () => void;
    setIsPlaying: (value: boolean) => void;
}

export const AudioPlayer = forwardRef((props: AudioPlayerProps, ref: any) => {
    const { isPlaying,setIsPlaying, handleNextAction } = props

    const [isReady, setIsReady] = React.useState(false);
    const [duration, setDuration] = React.useState(0);
    const [currrentProgress, setCurrrentProgress] = React.useState(0);
    const [buffered, setBuffered] = React.useState(0);
    const [volume, setVolume] = React.useState(0.5);
    const [openVolumn, setOpenVolumn] = React.useState(false)

    const durationDisplay = formatDurationDisplay(duration);
    const elapsedDisplay = formatDurationDisplay(currrentProgress);

    const handleBufferProgress: React.ReactEventHandler<HTMLAudioElement> = (
        e
    ) => {
        const audio = e.currentTarget;
        const dur = audio.duration;
        if (dur > 0) {
            for (let i = 0; i < audio.buffered.length; i++) {
                if (
                    audio.buffered.start(audio.buffered.length - 1 - i) <
                    audio.currentTime
                ) {
                    const bufferedLength = audio.buffered.end(
                        audio.buffered.length - 1 - i
                    );
                    setBuffered(bufferedLength);
                    break;
                }
            }
        }
    };

    const handleVolumeChange = (volumeValue: number) => {
        if (!ref.current) return;
        ref.current.volume = volumeValue;
        setVolume(volumeValue);
    };

    return (
        <div className="w-full h-full">
            <audio
                ref={ref}
                preload="metadata"
                onDurationChange={(e) => setDuration(e.currentTarget.duration)}
                onPlaying={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onEnded={() => {
                    console.log("Audio playback finished.");
                    handleNextAction && handleNextAction();
                }}
                onCanPlay={(e) => {
                    e.currentTarget.volume = volume;
                    setIsReady(true);
                }}
                onTimeUpdate={(e) => {
                    setCurrrentProgress(e.currentTarget.currentTime);
                    handleBufferProgress(e);
                }}
                onProgress={handleBufferProgress}
                onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
            />

            <div className="flex items-center gap-2 h-full justify-between">
                {/* <IconButton
                    disabled={!isReady}
                    onClick={togglePlayPause}
                    className="p-0"
                >
                    {isPlaying ? (
                        <PauseIcon className="text-black w-[32px] h-[32px]" />
                    ) : (
                        <PlayArrowIcon className="text-black w-[32px] h-[32px]" />
                    )}
                </IconButton> */}

                <AudioProgressBar
                    duration={duration}
                    currentProgress={currrentProgress}
                    buffered={buffered}
                    elapsedDisplay={elapsedDisplay}
                    durationDisplay={durationDisplay}
                    onChange={(e) => {
                        if (!ref.current) return;

                        ref.current.currentTime = e.currentTarget.valueAsNumber;

                        setCurrrentProgress(e.currentTarget.valueAsNumber);
                    }}
                />

                {/* <div className="flex gap-3 items-center justify-self-end">
                    <IconButton
                        onClick={() => setOpenVolumn(!openVolumn)}
                    >
                        {volume === 0 ? (
                            <VolumeOffIcon className={`${openVolumn ? 'text-[#FFB300]' : 'text-black'}`} />
                        ) : (
                            <VolumeUpIcon className={`${openVolumn ? 'text-[#FFB300]' : 'text-black'}`} />
                        )}
                    </IconButton>
                    {openVolumn && <VolumeInput volume={volume} onVolumeChange={handleVolumeChange} />}
                </div> */}
            </div>
        </div>
    );
})