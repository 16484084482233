import React from "react";
import { convertToMinutes, formatText } from "src/helper";
import {
  ClockIcon,
  CompletedIcon,
  CourseHeaderIcon,
  WaveBackground,
} from "../Common/Icons";
import { CourseInfoSkeleton } from "../Skeletons/CourseInfo";

type Props = {
  course?: any;
  currentStep: number;
  timesComplete: number;
  styleCard?: string;
  loadingCourse?: boolean;
  isBackToCourses?: boolean;
};

export const CourseInfo = (props: Props) => {
  const { course, styleCard, loadingCourse } = props;

  return (
    <div
      className={`w-full ${styleCard} bg-white border-1 border-solid border-black rounded-[8px] relative`}
    >
      <CourseHeaderIcon className="absolute left-1/2 transform -translate-x-1/2 top-[-10px]" />

      {loadingCourse ? (
        <CourseInfoSkeleton />
      ) : (
        <div className="flex flex-col relavite overflow-hidden">
          <WaveBackground className="absolute w-full h-full top-0 right-0 left-0" />

          <div className="pt-12 pb-7 px-8 flex flex-col gap-3">
            <p className="text-[14px] heading-font leading-[150%] font-medium">
              {course?.course_name}
            </p>

            <p
              className="text-[14px] font-light leading-[150%] line-clamp-[2px]"
              dangerouslySetInnerHTML={formatText(course.description, true)}
            >
              {/* {course?.description} */}
            </p>
          </div>

          <div className="p-6 flex items-center justify-end gap-3">
            <div className="flex gap-2 items-center">
              <ClockIcon className="w-[24px] h-[24px]" />
              <p className="text-[14px] font-light leading-[150%]">{`${convertToMinutes(
                course?.duration
              )} minutes`}</p>
            </div>

            {course?.course_completion && (
              <div className="flex gap-2 items-center">
                <CompletedIcon className="w-[24px] h-[24px]" />
                <p className="text-[14px] font-light leading-[150%]">
                  Completed
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
