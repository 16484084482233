import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { convertToMinutes } from "src/helper";
import { ClientMessageToClients } from "../Popup/ClientMessages";

interface CourseHeaderProps {
  name?: string;
  stakeholderWelcome?: string;
  coursesCompleted?: number;
  lessonsCompleted?: number;
  course?: any;
  loading?: boolean;
  bodyMessage?: any;
}

export const CourseHeader = (props: CourseHeaderProps) => {
  const {
    name,
    stakeholderWelcome,
    coursesCompleted,
    lessonsCompleted,
    course,
    loading,
    bodyMessage,
  } = props;

  const timeDuration = convertToMinutes(course?.fields.duration);

  const [openClientMessage, setOpenClientMessage] = useState(false);

  return (
    <>
      <div className="rounded-[14px] z-10 border-1 border-solid border-black p-3 bg-[#bacacf] flex flex-col items-center gap-2">
        <div className="border-1 border-solid border-black rounded-[8px] h-[62px] px-4 w-full bg-white flex items-center justify-center">
          {loading ? (
            <Skeleton variant="text" className="w-full text-lg" />
          ) : (
            <p
              className={`${
                !course?.fields?.course_name && "uppercase"
              } text-[14px] font-light leading-[150%]`}
            >
              {course?.fields?.course_name
                ? `COURSE: ${course?.fields?.course_name}`
                : ` welcome back, ${name}`}
            </p>
          )}
        </div>
        <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
          <div className="border-1 border-solid border-black rounded-[8px] h-[62px] px-4 w-full bg-white">
            {loading ? (
              <Skeleton variant="text" className="w-full text-lg" />
            ) : (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <p className="text-[14px] font-light leading-[150%]">
                  {course?.fields?.instructor
                    ? "FACILITATOR:"
                    : "Courses Completed:"}
                </p>
                <p className="text-[14px] font-light leading-[150%]">
                  {course?.fields?.instructor
                    ? course?.fields?.instructor
                    : coursesCompleted}
                </p>
              </div>
            )}
          </div>
          <div className="border-1 border-solid border-black rounded-[8px] h-[62px] px-4 w-full bg-white">
            {loading ? (
              <Skeleton variant="text" className="w-full text-lg" />
            ) : (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <p className="text-[14px] font-light leading-[150%]">
                  {timeDuration ? "APPROX:" : "Lessons Completed:"}
                </p>
                <p className="text-[14px] font-light leading-[150%]">
                  {timeDuration ? `${timeDuration} Minutes` : lessonsCompleted}
                </p>
              </div>
            )}
          </div>
        </div>
        <div
          onClick={() => setOpenClientMessage(true)}
          className="cursor-pointer border-1 border-solid border-black rounded-[8px] h-[62px] px-4 w-full bg-white flex items-center justify-center text-center"
        >
          {loading ? (
            <Skeleton variant="text" className="w-full text-lg" />
          ) : (
            <p className="text-[14px] font-light leading-[150%]">
              {stakeholderWelcome}
            </p>
          )}
        </div>
      </div>

      <ClientMessageToClients
        open={openClientMessage}
        bodyMessage={bodyMessage}
        handleExitMessage={() => setOpenClientMessage(false)}
      />
    </>
  );
};
