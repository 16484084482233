import React from "react";

interface InputProps {
  type: string;
  error?: any;
  label?: string;
  className?: string;
  required?: boolean;
  autoFocus?: boolean;
  value?: any;
  disabled?: boolean;
  placeholder?: string;
  rest?: any;
  helperText?: string
}

export const InputField = (props: InputProps) => {
  const {
    type,
    error,
    label,
    className,
    required = true,
    autoFocus = true,
    value,
    disabled,
    placeholder,
    rest,
    helperText
  } = props;

  return (
    <div className="flex flex-col gap-[8px]">
      {label && <p>{label}</p>}

      <input
        {...rest}
        className={`h-[45px] w-[296px] sm:w-[360px] border-[1px] border-solid ${error ? 'border-red' : 'border-black'} px-[12px] py-[16px] ${className}`}
        autoFocus={autoFocus}
        type={type}
        required={required}
        value={value ? value : rest.value}
        disabled={disabled}
        placeholder={placeholder}
      />

      {helperText && <p className="text-red">{helperText}</p>}
    </div>
  );
};
