import api from ".";

export const aboutMe = () => {
  return api.get("/user/me");
};

export const aboutMeViaEmail = (email: string) => {
  return api.get(`/user/me/${email}`);
};

export const getGroupInfo = (route: string) => {
  return api.get(`/user/group/${route}`)
} 
