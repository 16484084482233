import React from "react";
import { Modal } from "../../Common/Modal";
import {
  ClockIcon,
  WaveBackground,
} from "src/components/Common/Icons";
import { FooterModal } from "src/components/Common/Modal/Footer";
import { ButtonModal } from "src/components/Common/Modal/Button";
import { convertToMinutes, formatText } from "src/helper";
import "./index.css";

type Props = {
  open: boolean;
  course: any;
  handleExitMessage: () => void;
};

export const CourseDetailsMessage = (props: Props) => {
  const { open, handleExitMessage, course } = props;

  return (
    <Modal
      title="course details"
      open={open}
      height={80}
      customStyle={"p-[16px] sm:px-[192px]"}
      className="max-w-[328px] sm:max-w-[680px]"
    >
      <div>
        <div className="px-4 sm:px-3 pt-[88px] relative">
          <WaveBackground className="absolute w-full h-full top-0 right-0 left-0" />

          <div className="scroller overflow-y-auto px-0 sm:px-[44px] max-h-[397px] z-10 h-full w-full relative">
            <div className="gap-8 flex flex-col items-center justify-center">
              <div className="flex flex-col items-center justify-center gap-8 text-sm mb-[88px] ">
                <div className="flex flex-wrap items-center gap-2">
                  <div className="border-1 w-full sm:w-fit bg-white border-solid border-black rounded-[5px] px-5 py-2 min-h-[40px] flex text-[14px] leading-[150%] font-light items-center">
                    Course: {course.course_name}
                  </div>

                  <div className="hidden sm:flex border-1 bg-white border-solid border-black min-h-[40px] rounded-[5px] pl-3 sm:pl-5 pr-5 py-2 items-center">
                    <div className="flex gap-2 items-center">
                      <ClockIcon className="w-[24px] h-[24px]" />
                      <p className="text-[14px] leading-[150%] font-light">{`${convertToMinutes(
                        course?.duration
                      )} minutes`}</p>
                    </div>
                  </div>

                  <div className="flex flex-row items-center gap-2 h-full">
                    <div className="flex w-full h-full sm:hidden border-1 bg-white border-solid border-black min-h-[40px] rounded-[5px] px-3 sm:px-5 py-2  items-center">
                      <div className="flex gap-2 items-center">
                        <ClockIcon className="w-[24px] h-[24px]" />
                        <p className="text-[14px] leading-[150%] font-light">{`${convertToMinutes(
                          course?.duration
                        )} minutes`}</p>
                      </div>
                    </div>
                    {course?.category_name && (
                      <div className="border-1 w-full h-full text-center sm:w-fit bg-white min-h-[40px] border-solid border-black rounded-[5px] text-[14px] leading-[150%] font-light px-3 sm:px-5 py-2 flex justify-center items-center">
                        Course Category: {course.category_name}
                      </div>
                    )}
                  </div>

                  {course?.instructor && (
                    <div className="border-1 w-full sm:w-fit bg-white border-solid border-black rounded-[5px] text-[14px] min-h-[40px] leading-[150%] font-light px-5 flex items-center">
                      Facilitator: {course.instructor}
                    </div>
                  )}
                </div>

                <div
                  className="text-[14px] leading-[150%] font-light"
                  dangerouslySetInnerHTML={formatText(
                    course.full_description, false
                  )}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <FooterModal>
          <div className="flex items-center justify-center">
            <ButtonModal onClick={handleExitMessage} label={"Exit Message"} />
          </div>
        </FooterModal>
      </div>
    </Modal>
  );
};
