import { Skeleton } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { Logo, VectorHorizontal, VectorVertical } from "src/components/Common/Icons";
import { TitleBubble } from "src/components/TitleBubble";
import { getGroupInfo } from "src/redux/api/userApi";

type Props = {
  children?: JSX.Element;
  label: string;
  question?: string;
  goPage?: string;
  nextPage?: string;
  slogan?: string;
};

export const LayoutAuth = (props: Props) => {
  const { children, goPage, question, label, nextPage, slogan } = props;

  const params = useParams();

  const [loading, setLoading] = React.useState(true);

  const [groupInfo, setGroupInfo] = React.useState({
    group_name: "",
    url: "",
    filename: "",
    group_route: "",
  });

  React.useEffect(() => {
    setLoading(true);
    const fetchGroupData = async () => {
      await getGroupInfo(params.group || "")
        .then((res: any) => {
          const info = {
            ...res.groupInfo.fields,
            ...res.groupInfo.fields.logo[0],
          };

          setGroupInfo(info);
          typeof window !== "undefined" &&
            localStorage.setItem("groupInfo", JSON.stringify(info));
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          typeof window !== "undefined" && localStorage.removeItem("groupInfo");
        });
    };

    fetchGroupData();
  }, [params]);

  return (
    <div className="flex items-center justify-center w-full py-[64px]">
      <div className="m-auto border-[1px] divide-solid border-black overflow-hidden flex flex-col">
        <TitleBubble title={label} />

        <div className="px-[16px] sm:px-[160px] py-[88px] bg-white relative">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 h-[180px] w-[260px] overflow-hidden">
            <VectorHorizontal className="w-full h-full opacity-80" />
            <VectorVertical className="absolute top-0 opacity-80 left-1/2 transform -translate-x-1/2" />
          </div>
          <div className="max-w-[360px]">
            <div className="m-auto flex items-center justify-center">
              {loading ? (
                <div className="w-[150px] h-[100px] m-auto z-10 flex items-center justify-center">
                  <Skeleton variant="circular" width={60} height={60} />
                </div>
              ) : groupInfo.url ? (
                <img
                  src={groupInfo.url}
                  className="w-[150px] h-[100px] m-auto z-10"
                  alt={groupInfo.filename}
                />
              ) : (
                <Logo className="w-[48px] h-[48px] z-10" />
              )}
            </div>

            <div className="flex-1 w-full">
              <div className="flex flex-col items-center justify-center mt-8">
                {slogan && (
                  <p className="text-center mb-8 w-[296px] sm:w-full">
                    {slogan}
                  </p>
                )}

                {children}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-[4px] border-y-[1px] divide-solid border-black"></div>
        <div className="w-full h-[14px] bg-[#BAC9CF]"></div>
      </div>
    </div>
  );
};
