import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { AppState } from "../store";
import { getCourseJoinedById, getCoursesJoined } from "../api/coursesEnrollmentsApi";

const initialState = {
    coursesJoined: [] as any,
    courseJoinedDetail: {} as any,
    status: "loading",
    message: "",
};

export const getCoursesHaveJoined = createAsyncThunk("/user-courses", async () => {
    try {
        const res = await getCoursesJoined();
        return res;
    } catch (error) {
        console.log({ error });
        throw error;
    }
});

export const getCourseById = createAsyncThunk("/user-courses-detail", async (id: string | any) => {
    try {
        const res = await getCourseJoinedById(id)
        return res;
    } catch (error) {
        throw error;
    }
})

export const courseJoinedSlice = createSlice({
    initialState,
    name: "coursesJoined",
    reducers: {
        setCoursesJoined: (state, action) => {
            state.coursesJoined = action.payload;
        },
        setCourseJoinedDetail: (state, action) => {
            state.courseJoinedDetail = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCoursesHaveJoined.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getCoursesHaveJoined.fulfilled, (state, action) => {
                state.status = "idle";
                state.coursesJoined = action.payload;
            })
            .addCase(getCoursesHaveJoined.rejected, (state, action) => {
                state.status = "idle";
                state.coursesJoined = [];
            })
            .addCase(getCourseById.fulfilled, (state, action) => {
                state.courseJoinedDetail = action.payload;
            })
            .addCase(getCourseById.rejected, (state) => {
                state.courseJoinedDetail = {}
            });
    },
});

export const { setCoursesJoined, setCourseJoinedDetail } = courseJoinedSlice.actions;

export const getCourseJoined = (state: AppState) => get(state, "coursesJoined");
export const getCourseJoinedDetail = (state: AppState) => get(state, "courseJoinedDetail")

export default courseJoinedSlice.reducer;
