import { CircularProgress } from "@mui/material";
import React from "react";

interface Props {
  className?: string;
  label?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const AuthButton = (props: Props) => {
  const { label, loading, className, disabled, onClick } = props;

  return (
    <button
      className={`w-full px-6 py-3 rounded-[8px] ${
        !disabled ? "bg-[#bacacf] cursor-pointer" : "bg-[#ccc]"
      } ${className}`}
      onClick={onClick}
      type="submit"
      disabled={disabled}
    >
      {loading ? (
        <div className="flex items-center text-sm gap-2 justify-center pointer-events-none">
          Loading
          <CircularProgress
            className="w-[26px] h-[26px]"
            sx={{
              "& > svg": {
                width: "26px",
                height: "26px",
              },
              color: "#fff",
            }}
          />
        </div>
      ) : (
        <p className="text-sm font-medium text-black leading-[150%] font-['Hiragino_Sans']">
          {label}
        </p>
      )}
    </button>
  );
};
