import { AuthBody, ChangePasswordBody } from "src/types/auth";
import api from ".";
import { BodySession } from "src/types/session";

export const signUp = (body: AuthBody) => {
  return api.post("/auth/sign-up", body);
};

export const signIn = (body: AuthBody) => {
  return api.post("/auth/sign-in", body);
};

export const logOut = (body: BodySession) => {
  return api.post("/auth/log-out", body)
}

export const changePassword = (body: ChangePasswordBody) => {
  return api.patch("/auth/change-password", body)
}
