import React from "react";
import { Modal } from "../../Common/Modal";
import { CompleteMessageIcon, WarningIcon } from "src/components/Common/Icons";
import { FooterModal } from "src/components/Common/Modal/Footer";
import { ButtonModal } from "src/components/Common/Modal/Button";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

type Props = {
  open: boolean;
  rating: number | null;
  loading: boolean;
  setRating: (newRating: null | number) => void;
  handleBackDashboard: () => void;
  handleFeedback: () => void;
};

export const CourseCompleteMessage = (props: Props) => {
  const {
    open,
    rating,
    loading,
    setRating,
    handleFeedback,
    handleBackDashboard,
  } = props;

  return (
    <Modal
      title="Get Ready"
      open={open}
      height={56}
      customStyle={"p-[16px] sm:px-[76px]"}
      className="max-w-[328px] sm:max-w-[448px]"
    >
      <div>
        <div className="px-[56px] pt-[48px] pb-[88px] gap-8 flex flex-col items-center justify-center">
          <CompleteMessageIcon />

          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-center text-[14px] leading-[150%] font-light">
              You have successfully completed the course. Optionally, leave a
              course rating.
            </p>

            <Rating
              name="hover-feedback"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
              size="large"
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
          </div>
        </div>
        <FooterModal>
          <div className="flex flex-col items-center justify-center gap-2">
            <ButtonModal
              onClick={handleBackDashboard}
              label={"Back to Dashboard"}
            />
            <ButtonModal onClick={handleFeedback} loading={loading} label={"Course Feedback"} />
          </div>
        </FooterModal>
      </div>
    </Modal>
  );
};
