import * as React from "react";
import { getWidthProgress } from "src/helper";

interface Props {
  progress: number;
  loading?: boolean;
}

export const CourseProgress = (props: Props) => {
  const { progress, loading } = props;

  return (
    <div className="border-1 border-solid border-black rounded-[8px] bg-white p-2 w-full">
      <div className="h-[68px] w-full flex items-center border-1 border-solid border-black rounded-[8px] bg-white">
        <div
          className={`${
            loading ? "w-1" : getWidthProgress(progress)
          } relative h-full rounded-[8px] bg-[#bacacf]`}
        >
          <div className="h-full absolute top-0 right-[-8px] w-[16px] bg-white border-1 border-solid border-black rounded-full"></div>
        </div>

        <div className="flex justify-center items-center flex-1">
          {!loading && progress !== 1 && (
            <p className="text-center text-[14px] font-light leading-[150%]">
              Overall Progress {Math.round(progress * 100)}%
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
