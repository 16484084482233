import React from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { CheckYourEmail } from "src/pages/auth/CheckYourEmail";
import { ForgotPassword } from "src/pages/auth/ForgotPassword";
import { LoginPage } from "src/pages/auth/Login";
import { SetNewPassword } from "src/pages/auth/ResetPassword";
import { SignUpPage } from "src/pages/auth/SignUp";
import { ChatPage } from "src/pages/course-detail";
import Courses from "src/pages/courses";
import { ChangePassword } from "src/pages/profile/ChangePassword";
import { patchSession } from "src/redux/api/sessionApi";
import { logout } from "src/redux/features/authSlice";
import { resetSession } from "src/redux/features/sessionSlice";
import { useAppDispatch } from "src/redux/store";

export function AppRoutes() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userToken =
    typeof window !== "undefined" && localStorage.getItem("access_token");
  const groupInfo =
    localStorage.getItem("groupInfo") &&
    JSON.parse(localStorage.getItem("groupInfo") || "");

  const groupRoute =
    typeof window !== "undefined" && localStorage.getItem("group-route");

  let timeout: any;

  const handleLogout = () => {
    dispatch(resetSession());
    dispatch(logout());
    navigate(
      `${
        groupInfo?.group_route
          ? groupInfo?.group_route
          : groupRoute
          ? groupRoute
          : "no-group"
      }/login`
    );

    if (typeof window !== "undefined") {
      localStorage.removeItem("access_token");
      localStorage.removeItem("skip-modal");
    }
  };

  const startTimer = () => {
    timeout = setTimeout(async () => {
      await patchSession({ end_time: new Date() })
        .then((res) => {
          updateSessionStatus("Inactive");
          handleLogout();
        })
        .catch((error) => console.log({ error }));
    }, 3600000); // 900000 - 15 minutes /// 3600000 - 1 hour
  };

  const resetTimer = () => {
    if (timeout) clearTimeout(timeout);
    startTimer();
  };

  const updateSessionStatus = (status: string) => {
    // Logic to update the session status in Airtable
    console.log(`Updating session status to ${status}`);
    typeof window !== "undefined" &&
      localStorage.setItem("session_expired", "true");
  };

  React.useEffect(() => {
    startTimer();

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("mousedown", resetTimer);
    window.addEventListener("keypress", resetTimer);
    window.addEventListener("touchmove", resetTimer);

    return () => {
      if (timeout) clearTimeout(timeout);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("mousedown", resetTimer);
      window.removeEventListener("keypress", resetTimer);
      window.removeEventListener("touchmove", resetTimer);
    };
  }, []);

  // useEffect(() => {
  //   !userToken &&
  //     typeof window !== "undefined" &&
  //     !window?.location?.pathname?.includes("/signup") &&
  //     navigate(`${groupInfo?.group_route || "no-group"}/login`);
  // }, [userToken]);

  return (
    <Routes>
      <Route path="/:group/login" element={<LoginPage />} />
      <Route path="/:group/signup/*" element={<SignUpPage />} />
      <Route path="/:group/forgot-password" element={<ForgotPassword />} />
      <Route path="/:group/reset-password" element={<SetNewPassword />} />
      <Route path="/:group/check-your-email/*" element={<CheckYourEmail />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/courses/:id" element={<ChatPage />} />
      <Route path="/profile/change-password" element={<ChangePassword />} />

      <Route
        path="/*"
        element={
          <Navigate
            to={
              userToken
                ? "/courses"
                : `${groupRoute ? groupRoute : "no-group"}/login`
            }
            replace
          />
        }
      />
    </Routes>
  );
}
